import { PageProps } from 'gatsby'
import React from 'react'

import Layout from '../../components/layout'
import SEO from '../../components/seo'
import { AsideMenu } from './index'

import site from '../../_data/site.json'
import { SVG, easing } from '@svgdotjs/svg.js'
import useElementSize from '../../hooks/useElementSize'
import getAbsoluteOffset from '../../utils/getAbsoluteOffset'
import useHeaderLines from '../../hooks/useHeaderLines'

const WorkplaceEvaluation: React.FC<PageProps> = ({ location }) => {
  const { workplaceEvaluation } = site.pages.services
  const pageRef = React.useRef(null)
  const size = useElementSize(pageRef)
  const headerLines = useHeaderLines()

  React.useEffect(() => {
    if (pageRef.current && size) {
      const headerRect = getAbsoluteOffset(document.querySelector('.header'))
      const pageRect = getAbsoluteOffset(
        document.querySelector('.page--our-services'),
      )
      const preIntroRect = getAbsoluteOffset(
        document.querySelector('.pre-intro'),
      )
      const quoteRect = getAbsoluteOffset(
        document.querySelector('.subpage__content > blockquote'),
      )

      const svg = SVG()
        .addClass('topline')
        .addTo('body')
        .size('100%', document.body.clientHeight)
        .stroke({
          color: '#000',
          width: 5,
          miterlimit: 10,
        })

      const polyline = svg
        .polyline([
          ...headerLines,

          pageRect.left,
          headerRect.bottom,

          pageRect.left,
          preIntroRect.bottom,

          pageRect.right,
          preIntroRect.bottom,

          pageRect.right,
          quoteRect.top,

          quoteRect.left,
          quoteRect.top,

          quoteRect.left,
          quoteRect.bottom,

          pageRect.right,
          quoteRect.bottom,

          pageRect.right,
          pageRect.bottom - pageRect.height / 5,

          document.body.clientWidth,
          pageRect.bottom - pageRect.height / 5,
        ])
        .stroke({
          width: 0,
        })
      const polylineLength = polyline.node.getTotalLength()
      polyline
        .attr({
          'stroke-width': 5,
          'stroke-dashoffset': polylineLength,
          'stroke-dasharray': polylineLength,
        })
        .animate({ duration: 3000, delay: 0 })
        .ease(easing['<>'])
        .attr({
          'stroke-dashoffset': 0,
        })

      return () => svg.remove()
    }
  }, [size])

  return (
    <Layout>
      <SEO title="Workplace Evaluation" />
      <div className="pre-intro" style={{ height: 50 }}></div>
      <div ref={pageRef} className="container page--our-services">
        <AsideMenu links={site.navigation.services} location={location} />
        <main className="subpage subpage--workplace-evaluation">
          <img className="subpage__image" src={workplaceEvaluation.image} />
          <h1 className="subpage__title">{workplaceEvaluation.heading}</h1>
          <div
            className="subpage__content"
            dangerouslySetInnerHTML={{
              __html: workplaceEvaluation.content,
            }}
          />
        </main>
      </div>
    </Layout>
  )
}

export default WorkplaceEvaluation
